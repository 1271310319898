import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './auth.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './components/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: "encuesta-acuacultores",
        loadChildren: "./components/encuesta-acuacultores/encuesta-acuacultores.module#EncuestaAcuacultoresModule",
      },
      {
        path: "encuesta-asistentes",
        loadChildren: "./components/encuesta-asistentes/encuesta-asistentes.module#EncuestaAsistentesModule",
      },
      {
        path: "encuesta-expositores",
        loadChildren: "./components/encuesta-expositores/encuesta-expositores.module#EncuestaExpositoresModule",
      },
      {
        path: "reportes",
        loadChildren: "./components/reportes/reportes.module#ReportesModule",
      },
      {
        path: 'catalogos',
        loadChildren: './components/catalogos/catalogos.module#CatalogosModule',
      },
    ]
  }
];
