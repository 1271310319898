import { Component, OnInit } from "@angular/core";
import { NgxPermissionsService } from "ngx-permissions";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Globals } from "./globals";
import { UsuariosService } from "./services/usuarios.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  constructor(
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private http: HttpClient,
    private globlas: Globals,
    private usuariosService: UsuariosService
  ) {}

  ngOnInit() {
    this.usuariosService.check().subscribe(
      (res) => {},
      (err) => {
        this.usuariosService.signOut();
      }
    );
    if (localStorage.getItem("jwtToken")) {
      var token = JSON.parse(localStorage.getItem("jwtToken"));
      const perm = token.user.permisos;
      this.permissionsService.loadPermissions(perm);
      // this.router.navigate(["/dashboard"]);
      //RENOVAR TOKEN SI YA VENCIO
    }
  }
}
