import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";

import { SidebarModule } from "./shared/sidebar/sidebar.module";
import { FooterModule } from "./shared/footer/footer.module";
import { NavbarModule } from "./shared/navbar/navbar.module";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AppRoutes } from "./app.routing";
import { JwtInterceptor } from "./http.interceptor";
import { ErrorInterceptor } from "./error.interceptor";
import { AuthGuard } from "./auth.guard";
import { LoginComponent } from "./components/login/login.component";
import { Globals } from "./globals";
// no son de la plantilla
import { AngularTokenModule } from "angular-token";
import { NgxPermissionsModule } from "ngx-permissions";
import { ToastrModule } from "ng6-toastr-notifications";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgSelectModule } from "@ng-select/ng-select";
import { FileUploadModule } from "ng2-file-upload";
import { AgmCoreModule } from "@agm/core";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes),
    NgbModule.forRoot(),
    HttpModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    HttpClientModule,
    AngularTokenModule.forRoot({
      apiBase: "https://apiconacua.hdsoftware.mx",
      apiPath: null,
      signInPath: "auth/login",
      signInRedirect: "/dashboard",
      signInStoredUrlStorageKey: "token_bba",
      signOutPath: "/",
      loginField: "usuario",
    }),
    AgmCoreModule.forRoot({
      apiKey: "",
    }),
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),
    NgxDatatableModule,
    NgxSmartModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    FileUploadModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    LoginComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    Globals,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class AppModule {}
