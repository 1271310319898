import { Injectable } from "@angular/core";

@Injectable()
export class Globals {
  apiUrl: string = "https://apiconacua.hdsoftware.mx";
  permisosdisponibles = [
    "DASHBOARD",
    "ENCUESTA ACUACULTORES",
    "ENCUESTA ASISTENTES",
    "ENCUESTA EXPOSITORES",
    "REPORTES",
    "USUARIOS",
  ];
}
